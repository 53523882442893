import { Typography } from 'antd';
import { useEffect, useState } from 'react';
import List from './List';
import { IScript, useScripts } from '../../../hooks/scripts';
import Loading from '../../Common/Loading';

const ContentScripts = () => {
  const getScripts = useScripts();
  const [scripts, setScripts] = useState<IScript[]>();

  useEffect(() => {
    getScripts.fetch().then((res) => setScripts(res?.data));
  }, []);

  return (
    <div className="flex-col gap-24">
      <Loading visible={getScripts.loading} absolute />
      <Typography.Title>Content Scripts</Typography.Title>
      {!scripts || scripts.length === 0 ? null : <List scripts={scripts} />}
    </div>
  );
};

export default ContentScripts;
