import Icon from '@ant-design/icons';
import { Button, Col, Row, Space, Steps, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Back } from '../../../../Common/Icon';
import ReportRow from './activityRow';
import { REPORT_TABLE_DATA } from '../../../../../utils/constantData';
import { customDot } from '../../../../../utils';

const ActivitiesById = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [report, setReport] = useState<any>();

  useEffect(() => {
    if (!id) return;

    setReport(REPORT_TABLE_DATA.find((item) => item.id === id));
  }, [id]);

  const steps = report
    ? [
        {
          status: 'finish' as 'wait' | 'process' | 'finish' | 'error',
          description: (
            <div style={{ paddingTop: '2rem', maxWidth: '230px' }}>
              <ReportRow report={report} />
            </div>
          ),
          className: 'steps',
        },
      ]
    : [
        {
          status: 'wait' as 'wait' | 'process' | 'finish' | 'error',
          className: 'default-class',
          description: <div>No activities found</div>,
        },
      ];

  const handleBack = () => {
    navigate({ pathname: '/reports' });
  };

  return (
    <section>
      {report && (
        <Row>
          <Col span={24}>
            <Space direction="vertical" size={24}>
              <Space size={4}>
                <Button
                  type="ghost"
                  icon={<Icon component={Back} style={{ fontSize: '1.25em' }} />}
                  onClick={handleBack}
                />
                <Typography.Title style={{ marginLeft: '20px' }}>
                  Activities for report {report?.name.toLocaleUpperCase() || '(Unnamed Report)'}
                </Typography.Title>
              </Space>
              <Steps
                style={{ marginLeft: '20px' }}
                progressDot={customDot}
                direction="vertical"
                items={[...(steps || []), { status: 'finish', className: 'd-none' }]}
              />
            </Space>
          </Col>
        </Row>
      )}
    </section>
  );
};

export default ActivitiesById;
