import { Col, Row } from 'antd';
import CurrentPlan from './CurrentPlan';
import PaymentMethod from './PaymentMethod';

const Payment = () => (
  <Row gutter={[20, 20]}>
    <Col xl={12} span={24}>
      <CurrentPlan />
    </Col>
    <Col xl={12} span={24}>
      <PaymentMethod />
    </Col>
  </Row>
);

export default Payment;
