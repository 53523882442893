import { Button, Col, Input, Row, Space } from 'antd';
import Form from 'antd/es/form';
import { useForm } from 'antd/es/form/Form';
import clsx from 'clsx';
import React from 'react';
import { JsonResult } from '../../../types';

interface IStripeForm {
  isControlsBlock?: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  options: JsonResult;
  handleNext: (id: string | null) => void;
  handleBack: () => void;
}

const StripeForm = ({ isControlsBlock, cancelButtonText, confirmButtonText, handleBack }: IStripeForm) => {
  const [form] = useForm();

  return (
    <Space direction="vertical" size={24}>
      <Form form={form} layout="vertical" autoComplete="off">
        <Form.Item name="name" label="Name of card" style={{ marginBottom: 12 }}>
          <Input size="large" placeholder="Enter Card Name" />
        </Form.Item>
        <Row>
          <Col span={24}>
            <div id="card-element" />
          </Col>
        </Row>
      </Form>
      <Row gutter={24} className={clsx({ 'flex-justify-end': !isControlsBlock })}>
        <Col span={12} className={clsx({ 'w-fit-content': !isControlsBlock })}>
          <Button size="large" type="default" block={isControlsBlock} onClick={handleBack}>
            {cancelButtonText}
          </Button>
        </Col>
        <Col span={12} className={clsx({ 'w-fit-content': !isControlsBlock })}>
          <Button
            size="large"
            type="primary"
            // onClick={handleConfirm}
            block={isControlsBlock}
          >
            {confirmButtonText}
          </Button>
        </Col>
      </Row>
    </Space>
  );
};

StripeForm.defaultProps = {
  clientSecret: undefined,
  isControlsBlock: false,
  cancelButtonText: 'Cancel',
  confirmButtonText: 'Confirm',
};

export default StripeForm;
