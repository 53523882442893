import { Space, Button } from 'antd';
import { DraggableProvided } from 'react-beautiful-dnd';
import ReportSection from '../Section';

interface IRentalAnalysis {
  provided?: DraggableProvided;
}

const RentalAnalysis = ({ provided }: IRentalAnalysis) => {
  const name = 'rentalAnalysis';

  return (
    <ReportSection
      id={name}
      name={name}
      title="Rental Analysis"
      description="This section shows the average monthly rent in the zip code of your subject property.
     Toggle between the different property types to see how rents have 
    changed over time."
      provided={provided}
    >
      <Space
        direction="vertical"
        size={20}>
        <div style={{ position: 'relative', padding: "100px" }}>
          <Button
            size='large'
            type='primary'
            onClick={() => { window.open('https://app.mortgagemaker.ai/sign-up', '_blank') }}
            style={{
              width: '220px',
              position: 'absolute',
              zIndex: 5,
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}>
            Register to see live data
          </Button>
        </div>
      </Space>
    </ReportSection>
  );
};

RentalAnalysis.defaultProps = {
  provided: undefined,
};

export default RentalAnalysis;
