import { Button, Empty, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import Stripe from 'stripe';
import { useContextNotifications } from '../../../../../context/notifications';
import ContentCard from '../../../../Common/ContentCard';
import PaymentItem from '../../../../Common/PaymentItem';

import styles from './index.module.less';
import PaymentMethodModal from './PaymentMethodModal';
import { PAYMENT_METHOD } from '../../../../../utils/constantData';

const PaymentMethod = () => {
  const { openConfirm } = useContextNotifications();
  const [paymentMethod, setPaymentMethod] = useState<Stripe.PaymentMethod | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const loadPaymentMethods = () => {
    const defaultPaymentMethod = PAYMENT_METHOD.find((item) => item.id === 'pm_2OuDkXAP5k49x0bIGBZGF0gq');

    setPaymentMethod(defaultPaymentMethod || null);
  };

  useEffect(() => {
    loadPaymentMethods();
  }, []);

  return (
    <>
      <PaymentMethodModal open={isModalOpen} onCancel={() => setIsModalOpen(false)} />
      <ContentCard
        bordered
        padding={20}
        height="100%"
        footer={
          <div className={styles.footer}>
            <Space size={12} className={styles.buttonWrapper}>
              {paymentMethod && (
                <Button
                  type="default"
                  danger
                  size="large"
                  onClick={() => {
                    openConfirm?.({
                      title: 'Delete Payment Method ',
                      content: 'Are you sure you want to delete Payment Method?',
                      okText: 'Delete',
                      okType: 'danger',
                      // onOk: (handleClose) => handleDelete(handleClose),
                    });
                  }}
                >
                  Delete
                </Button>
              )}

              <Button
                type="default"
                className="btn-default-dark-blue"
                size="large"
                onClick={() => setIsModalOpen(true)}
              >
                {paymentMethod ? 'Change payment method' : 'Add payment method'}
              </Button>
            </Space>
          </div>
        }
      >
        <div className={styles.content}>
          <div>
            <Typography.Title level={3}>Payment method</Typography.Title>
            <Typography className="color-gray">Change how you pay for your plan.</Typography>
          </div>
          {paymentMethod?.card ? (
            <PaymentItem card={paymentMethod.card} />
          ) : (
            <Empty style={{ margin: 0 }} image={Empty.PRESENTED_IMAGE_SIMPLE} description="No default payment method" />
          )}
        </div>
      </ContentCard>
    </>
  );
};

export default PaymentMethod;
