import { Button, Col, Row, Space, Typography } from 'antd';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import ContentCard from '../../Common/ContentCard';
import NotFoundContent from '../../Common/NotFoundContent';
import NotificationRow from './NotificationRow';
import { NOTIFICATIONS_DATA } from '../../../utils/constantData';

const Notifications = () => {
  const [notificationsList, setNotificationList] = useState(NOTIFICATIONS_DATA);
  const [activeFilter, setActiveFilter] = useState<string | null>(null);

  useEffect(() => {
    setNotificationList(
      NOTIFICATIONS_DATA.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()).filter(
        (item) => {
          if (activeFilter) return item.type === activeFilter;

          return item;
        }
      )
    );
  }, [activeFilter]);

  return (
    <section>
      <Row>
        <Col xl={12} span={24}>
          <Space direction="vertical" size={20}>
            <Space direction="vertical" size={28}>
              <Typography.Title>Notification</Typography.Title>
              <Space size={12}>
                <Button
                  type={!activeFilter ? 'primary' : 'ghost'}
                  className={clsx({ 'bg-light-gray': !!activeFilter })}
                  onClick={() => setActiveFilter(null)}
                >
                  All
                </Button>
                <Button
                  type={activeFilter === 'report' ? 'primary' : 'ghost'}
                  className={clsx({ 'bg-light-gray': activeFilter !== 'report' })}
                  onClick={() => setActiveFilter('report')}
                >
                  Report
                </Button>
                <Button
                  type={activeFilter === 'activity' ? 'primary' : 'ghost'}
                  className={clsx({ 'bg-light-gray': activeFilter !== 'activity' })}
                  onClick={() => setActiveFilter('activity')}
                >
                  Activity
                </Button>
                <Button
                  type={activeFilter === 'system' ? 'primary' : 'ghost'}
                  className={clsx({ 'bg-light-gray': activeFilter !== 'system' })}
                  onClick={() => setActiveFilter('system')}
                >
                  System
                </Button>
              </Space>
            </Space>
            <ContentCard>
              {notificationsList?.length ? (
                <Space direction="vertical" size={8}>
                  {notificationsList?.map((item) => (
                    <NotificationRow key={item.id} item={item} />
                  ))}
                </Space>
              ) : (
                <NotFoundContent />
              )}
            </ContentCard>
          </Space>
        </Col>
      </Row>
    </section>
  );
};

export default Notifications;
