import { Col, Form, Radio, RadioChangeEvent, Row, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import debounce from "just-debounce-it";
import SettingsList from './SettingsList';
import { editorModules } from '../../Reports/Sections/WelcomeText';
import { REPORT_TYPE_OPTIONS } from '../../Reports/constants';
import { SETTINGS_DATA_WITH_TYPE, USER_DATA } from '../../../../utils/constantData';

import 'react-quill/dist/quill.snow.css';
import './style.less';
import { EnumReportTypes } from '../../../../types/reports';

type TextType = 'closingText' | 'welcome_message';

const Settings = () => {
  const [welcomeHtmlContent, setWelcomeHtmlContent] = useState('');
  const [closingHtmlContent, setClosingHtmlContent] = useState('');
  const [defaultReportType, setDefaultReportType] = useState<EnumReportTypes>(EnumReportTypes.purchase);
  const [settings, setSettings] = useState<any | null>(null);

  useEffect(() => {
    setWelcomeHtmlContent(USER_DATA.welcome_message || '');
    setClosingHtmlContent(USER_DATA.closingText || '');
  }, []);

  const handleTextChange = (textType: TextType, htmlContent: string) => {
    if (textType === 'closingText') {
      setClosingHtmlContent(htmlContent);
    } else if (textType === 'welcome_message') {
      setWelcomeHtmlContent(htmlContent);
    }
  };

  const debouncedHandleTextChange = debounce((textType: TextType, htmlContent: string) => {
    handleTextChange(textType, htmlContent);
  }, 1000);

  const onDefaultTypeChange = ({ target }: RadioChangeEvent) => {
    const value = target.value as EnumReportTypes;

    setDefaultReportType(value);

    setSettings(SETTINGS_DATA_WITH_TYPE[value]);
  };

  return (
    <Row gutter={[20, 20]}>
      <Col xl={8} span={24}>
        <Space direction="vertical" size={20}>
        <Typography.Title level={3}>Default Report Type</Typography.Title>
          <Radio.Group
            options={REPORT_TYPE_OPTIONS}
            onChange={onDefaultTypeChange}
            value={defaultReportType}
            optionType="button"
            buttonStyle="solid"
            className='custom-radio-group'
          />
          <SettingsList
            items={settings?.marketNotification || []}
            label=""
            description="Receive interest rate reprice alerts."
          />
          <SettingsList items={settings?.generalNotification || []} label="General notification" />
          <SettingsList
            draggable
            items={settings?.tableView || []}
            label="Default table view "
            description="Adjust how you view the report table, and you can also change their order by dragging them"
          />
        </Space>
      </Col>
      <Col xl={{ span: 9, offset: 4 }} span={24} className="flex-col gap-20">
        <SettingsList
          draggable
          items={settings?.reportView || []}
          label="Default report view"
          description="Adjust how you view the open report, and you can also change their order by dragging them"
        />
        <div>
          <div className="flex-col gap-4">
            <Typography.Text className="fs-0875 fw-500">Welcome Text Default</Typography.Text>
            <Form.Item name={['profile', 'welcome_message']} label="Enter Text" noStyle>
              <div className="custom-quill-editor" style={{ marginBottom: '15px' }}>
                <ReactQuill
                  modules={editorModules}
                  theme="snow"
                  value={welcomeHtmlContent}
                  onChange={(content) => debouncedHandleTextChange('welcome_message', content)}
                  placeholder="Enter welcome text"
                />
              </div>
            </Form.Item>
          </div>
          <div className="flex-col gap-4">
            <Typography.Text className="fs-0875 fw-500">Closing Text Default</Typography.Text>
            <Form.Item name={['profile', 'closingText']} label="Enter Text" noStyle>
              <div className="custom-quill-editor">
                <ReactQuill
                  modules={editorModules}
                  theme="snow"
                  value={closingHtmlContent}
                  onChange={(content) => debouncedHandleTextChange('closingText', content)}
                  placeholder="Enter closing text"
                />
              </div>
            </Form.Item>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Settings;
