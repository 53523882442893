import { useLocation } from 'react-router-dom';
import Layout from '../../../components/Layout/Report';
import Content from '../../../components/Pages/Reports/Edit';
import { displayName } from '../../../config';
import ReportsProvider from '../../../context/reports';

const ReportDetails = (): JSX.Element => {
  const location = useLocation();

  const isEditPath = location.pathname.includes('/edit');
  const isPreview = location.pathname.includes('/preview');

  document.title = !isEditPath || !isPreview ? 'Your Mortgage Review' : `${displayName}: Report Details`;

  return (
    <ReportsProvider value={{ isBorrower: true }}>
      <Layout>
        <Content/>
      </Layout>
    </ReportsProvider>
  );
};

export default ReportDetails;
