import qs from 'querystring';
import { Space, Tabs, TabsProps, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Details from './Details';
import Payment from './Payment';
import Settings from './Settings';

interface IWrapper {
  children: React.ReactNode;
}

const Wrapper = ({ children }: IWrapper) => <div style={{ padding: '20px 0' }}>{children}</div>;

const Profile = (): JSX.Element => {
  const navigation = useNavigate();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState<string>('details');

  useEffect(() => {
    const { tab } = qs.parse(location.search.replace('?', ''));

    setActiveKey(tab as string);
  }, [location]);

  const handleTabChange = (tab: string) => {
    const params = { tab };

    navigation({ pathname: location.pathname, search: qs.stringify(params) });
  };

  const items: TabsProps['items'] = [
    {
      key: 'details',
      label: 'Profile details',
      children: (
        <Wrapper>
          <Details />
        </Wrapper>
      ),
    },
    {
      key: 'settings',
      label: 'Settings',
      children: (
        <Wrapper>
          <Settings />
        </Wrapper>
      ),
    },
    {
      key: 'payment',
      label: 'Payment',
      children: (
        <Wrapper>
          <Payment />
        </Wrapper>
      ),
    },
  ];

  return (
    <section>
      <Space direction="vertical" size={32}>
        <Typography.Title>My Profile</Typography.Title>
        <Tabs activeKey={activeKey} tabPosition="top" items={items} onChange={handleTabChange} />
      </Space>
    </section>
  );
};

export default Profile;
