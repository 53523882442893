import { SortOrder } from 'antd/es/table/interface';
import { StepsProps } from 'antd';
import * as Sentry from '@sentry/react';
import { JsonResult } from '../types';

export function capitalizeFirstLetter(str: string): string {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

/**
 * Returns a JS object representation of a Javascript Web Token from its common encoded
 * string form.
 *
 * @template T the expected shape of the parsed token
 * @param {string} token a Javascript Web Token in base64 encoded, `.` separated form
 * @returns {(T | undefined)} an object-representation of the token
 * or undefined if parsing failed
 */
export function getParsedJwt<T = JsonResult>(token?: string): T | undefined {
  try {
    return token ? JSON.parse(atob(token.split('.')[1])) : undefined;
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
}

export const getSorterParams = (sorter: Record<string, SortOrder>): { [key: string]: string } => {
  const sorterEntries = Object.entries(sorter);
  const newParams: { [key: string]: string } = {};

  if (sorterEntries.length) {
    newParams.orderByColumn = sorterEntries[0][0] || '';
    newParams.orderBy = sorterEntries[0][1] === 'ascend' ? 'ASC' : 'DESC';
  }

  return newParams;
};

export const queryFilterParams = (queryParams: Record<string, string>): Record<string, string> => {
  const params = Object.entries(queryParams);
  const newParams: { [key: string]: string } = {};

  params.forEach(([key, value]) => {
    if (value) {
      newParams[key] = value.toString();
    }
  });

  return newParams;
};

export const generateUniqueId = (): string => `${new Date().getTime()}-${Math.floor(Math.random() * 10000)}`;

export const customDot: StepsProps['progressDot'] = (dot) => dot;

export const fetchIp = async () => {
  const response = await fetch('https://api.ipify.org?format=json');
  const data = await response.json();

  return data
};