import { IScript } from '../../../../hooks/scripts';
import styles from '../index.module.less';
import Tile from '../Tile';

const List = ({ scripts }: { scripts: IScript[] | undefined }) => (
  <div className={styles.scriptsGrid}>
    {scripts &&
      scripts.map((script, index) => (
        <div key={script.id} className={styles.scriptCol}>
          <Tile script={script} blurred={index >= 2} />
        </div>
      ))}
  </div>
);

export default List;
