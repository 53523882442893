import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import ContentCard from '../../../Common/ContentCard';
import MarketRatesChart from '../../../Common/MarketRatesChart';
import { MARKET_RATE_30 } from '../../../../utils/constantData';

const MarketRates = () => {
  const navigate = useNavigate();

  const handleViewAll = () => {
    navigate('/market-rates');
  };

  return (
    <ContentCard height="100%">
      <MarketRatesChart
        homePage
        title="UMBS 30 Year - 5.5% Coupon"
        data={MARKET_RATE_30}
        height={250}
        controls={
          <Button type="text" className="color-gray uppercase" onClick={handleViewAll}>
            View All
          </Button>
        }
      />
    </ContentCard>
  );
};

export default MarketRates;
