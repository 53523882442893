import Icon from '@ant-design/icons';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { Button, Space, Radio, RadioChangeEvent, Row, Col } from 'antd';
import clsx from 'clsx';
import React, { useState } from 'react';
import Stripe from 'stripe';
import { Plus } from '../Icon';
import StripeForm from '../PaymentForm';
import PaymentItem from '../PaymentItem';

import styles from './index.module.less';

interface IPaymentList {
  paymentMethods?: Stripe.PaymentMethod[];
  selectedId: string | null;
  loading?: boolean;
  reloadPaymentMethods?: () => void;
  handleNext?: (id: string | null) => void;
  handleBack?: () => void;
  isControlsBlock?: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
}

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY as string);

const PaymentsList = ({
  paymentMethods,
  selectedId,
  loading,
  handleNext,
  handleBack,
  isControlsBlock,
  cancelButtonText,
  confirmButtonText,
}: IPaymentList) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<string | null>(selectedId);

  const options: StripeElementsOptions = {
    mode: 'setup',
    locale: 'en',
    loader: 'always',
    currency: 'usd',
    paymentMethodCreation: 'manual',
    paymentMethodTypes: ['card'],
    appearance: {
      variables: {
        colorPrimary: getComputedStyle(document.documentElement).getPropertyValue('--color-dark-blue'),
      },
    },
  };

  const handeNextStep = (id: string | null) => {
    handleNext?.(id);
  };

  const handleAddNewCard = (id: string | null) => {
    setIsEditMode(false);
    handeNextStep(id);
  };

  const handleRadioChange = (e: RadioChangeEvent) => {
    const { value } = e.target;

    setCurrentPaymentMethod(value);
  };

  return (
    <>
      {!isEditMode && !!paymentMethods?.length && (
        <Space size={24} direction="vertical">
          <Radio.Group value={currentPaymentMethod} onChange={handleRadioChange}>
            <Space align="end" className={styles.row}>
              <Space direction="vertical" size={20}>
                {paymentMethods.map((paymentMethod) => (
                  <Radio key={paymentMethod.id} value={paymentMethod.id}>
                    <PaymentItem card={paymentMethod.card} />
                  </Radio>
                ))}
              </Space>
              <Button
                disabled
                className={styles.button}
                type="ghost"
                icon={<Icon component={Plus} />}
                onClick={() => setIsEditMode(true)}
              >
                Add New
              </Button>
            </Space>
          </Radio.Group>
          {(handleBack || handleNext) && (
            <Row gutter={24} className={clsx({ 'flex-justify-end': !isControlsBlock })}>
              {handleBack && (
                <Col span={12} className={clsx({ 'w-fit-content': !isControlsBlock })}>
                  <Button size="large" type="default" block={isControlsBlock} onClick={handleBack} disabled={loading}>
                    {cancelButtonText}
                  </Button>
                </Col>
              )}
              {handleNext && (
                <Col span={12} className={clsx({ 'w-fit-content': !isControlsBlock })}>
                  <Button
                    size="large"
                    type="primary"
                    block={isControlsBlock}
                    onClick={() => handeNextStep(currentPaymentMethod)}
                    loading={loading}
                    disabled={loading || !currentPaymentMethod}
                  >
                    {confirmButtonText}
                  </Button>
                </Col>
              )}
            </Row>
          )}
        </Space>
      )}

      {(!paymentMethods?.length || isEditMode) && (
        <Elements stripe={stripePromise} options={options}>
          <StripeForm
            isControlsBlock={isControlsBlock}
            confirmButtonText={confirmButtonText}
            cancelButtonText={cancelButtonText}
            options={options}
            handleNext={handleAddNewCard}
            handleBack={!paymentMethods?.length && handleBack ? handleBack : () => setIsEditMode(false)}
          />
        </Elements>
      )}
    </>
  );
};

PaymentsList.defaultProps = {
  paymentMethods: undefined,
  loading: false,
  handleNext: undefined,
  handleBack: undefined,
  isControlsBlock: false,
  confirmButtonText: 'Next',
  cancelButtonText: 'Back',
  reloadPaymentMethods: undefined,
};

export default PaymentsList;
