/* eslint-disable no-nested-ternary */
import React from 'react';
import { Typography } from 'antd';
import { ICurrentMarketRates } from '../../../hooks/reports';
import ContentCard from '../ContentCard';
import CandlestickChart from './chart';

interface IMarketRatesChart {
  title: string;
  data?: ICurrentMarketRates[];
  height: number;
  reversed?: boolean;
  controls?: React.ReactNode;
  homePage?: boolean;
}

const MarketRatesChart = ({ title, data, height, reversed, controls, homePage }: IMarketRatesChart) => {
  const colorRed = getComputedStyle(document.documentElement).getPropertyValue('--color-tag-red');
  const colorGreen = getComputedStyle(document.documentElement).getPropertyValue('--color-tag-green');
  const isMobile = window.outerWidth < 768;

  const getDifference = (value?: number, prevValue?: number) => {
    if (!value || !prevValue) return { diff: 0, color: 'inherit' };

    const diff = value - prevValue;
    let color = colorGreen;

    if (diff > 0 && reversed) {
      color = colorRed
    } else if (diff > 0 && !reversed) {
      color = colorGreen
    } else if (diff <= 0 && reversed) {
      color = colorGreen
    } else if (diff <= 0 && !reversed) {
      color = colorRed
    }

    return {
      diff:
        diff > 0
          ? `+${Math.abs(diff).toFixed(title === 'UMBS 30 Year - 5.5% Coupon' ? 2 : 3)}`
          : `-${Math.abs(diff).toFixed(title === 'UMBS 30 Year - 5.5% Coupon' ? 2 : 3)}`,
      color,
    };
  };

  const difference = getDifference(data?.[data.length - 1].close, data?.[data.length - 2].close);

  return (
    <ContentCard bordered padding={isMobile ? 5 : 20}>
      <div style={{display:'flex', justifyContent: homePage ? 'center' : 'start'}}>
        <div className="flex-col gap-12">
          <div className="flex-col gap-4">
            <div className={`flex-row ${isMobile ? 'gap-4' : 'gap-20'} flex-align-center flex-justify-space-between`}>
              <div className={`flex-row ${isMobile ? 'gap-4 flex-col' : 'gap-20 flex-row'}`}>
                <Typography.Title level={2} style={{ color: '#464646' }}>
                  {title}
                </Typography.Title>
                {data?.[0].close && data?.[1].close && (
                  <div className="flex-row gap-4 flex-align-center">
                    <Typography.Text className="color-dark fs-1">
                      {data[data.length - 1].close.toFixed(title === 'UMBS 30 Year - 5.5% Coupon' ? 2 : 3)}
                    </Typography.Text>
                    <Typography.Text style={{ color: difference.color }}>
                      ({difference.diff})
                    </Typography.Text>
                  </div>
                )}
              </div>
              {controls}
            </div>
            {data && (
              <div className={`flex-row ${isMobile ? 'gap-4' : 'gap-20'}`}>
                <div className="flex-row gap-4">
                  <Typography.Text className="color-gray uppercase">O</Typography.Text>
                  <Typography.Text style={{ color: difference.color }}>
                    {data[data.length - 1].open.toFixed(3)}
                  </Typography.Text>
                </div>
                <div className="flex-row gap-4">
                  <Typography.Text className="color-gray uppercase">H</Typography.Text>
                  <Typography.Text style={{ color: difference.color }}>
                    {data[data.length - 1].high.toFixed(3)}
                  </Typography.Text>
                </div>
                <div className="flex-row gap-4">
                  <Typography.Text className="color-gray uppercase">L</Typography.Text>
                  <Typography.Text style={{ color: difference.color }}>
                    {data[data.length - 1].low.toFixed(3)}
                  </Typography.Text>
                </div>
                <div className="flex-row gap-4">
                  <Typography.Text className="color-gray uppercase">C</Typography.Text>
                  <Typography.Text style={{ color: difference.color }}>
                    {data[data.length - 1].close.toFixed(3)}
                  </Typography.Text>
                </div>
                <div className="flex-row gap-4">
                  <Typography.Text style={{ color: difference.color }}>
                    ({getDifference(data?.[data.length - 1].close, data?.[data.length - 2].close).diff})
                  </Typography.Text>
                </div>
              </div>
            )}
          </div>
          <ContentCard loading={!data}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ margin: '0 -20px -20px' }}>
                <CandlestickChart height={height} data={data} isMobile={isMobile}/>
              </div>
            </div>
          </ContentCard>
        </div>
      </div>
    </ContentCard>
  );
};

MarketRatesChart.defaultProps = {
  data: undefined,
  reversed: false,
  controls: undefined,
  homePage: false,
};

export default MarketRatesChart;
