import { FetchResponse } from '../types';
import { FetchGet, useFetchGet } from './fetch';

export interface INews {
  id: number;
  header: string;
  category: string;
  body: string;
  author: string;
  origin: string;
  imgUrl: string;
  originalContent: string;
  pubDate: string;
  link: string;
  prompts: string;
}

export interface INewsResponse {
  data: INews[];
  totalRecords: number;
}

export interface INewsParams {
  id?: string;
  page?: number;
  pageSize?: number;
  searchQuery?: string;
}

export const useNews = (): FetchGet<FetchResponse<INewsResponse>, INewsParams> =>
  useFetchGet('news', { autoStart: false, startStateLoading: true, multiple: 'news' });
