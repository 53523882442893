import { Modal, ModalFuncProps, Space, Typography } from 'antd';
import React from 'react';
import PaymentsList from '../../../../../Common/PaymentsList';
import { JsonResult } from '../../../../../../types';
import { PAYMENT_METHOD } from '../../../../../../utils/constantData';

interface IPaymentMethodModal extends ModalFuncProps {
  id?: string;
  data?: JsonResult;
}

const PaymentMethodModal = ({ open, onOk, onCancel }: IPaymentMethodModal) => {
  const customerPaymentMethods = PAYMENT_METHOD;

  const paymentMethods = customerPaymentMethods;

  return (
    <Modal closable={false} footer={null} open={open} onCancel={onCancel} wrapClassName="modal">
      <Space direction="vertical" size={20}>
        <Typography.Title level={2}>Change Payment Method</Typography.Title>
        <PaymentsList
          paymentMethods={paymentMethods}
          selectedId={null}
          handleNext={onOk}
          handleBack={onCancel}
          confirmButtonText="Continue"
          cancelButtonText="Cancel"
        />
      </Space>
    </Modal>
  );
};

PaymentMethodModal.defaultProps = {
  id: undefined,
  data: {},
};

export default PaymentMethodModal;
