import moment from 'moment-timezone';
import { ProColumns } from '@ant-design/pro-table';
import React, { useState } from 'react';
import Table from '../../../../Common/Table';
import { IAllViewed, IUserViews } from '../../../../../hooks/userViews';
import './styles.less';
import { ALL_VIEWED_REPORTS } from '../../../../../utils/constantData';

const ExpandedRow = ({ record }: { record: IAllViewed }) => (
  <Table<IUserViews>
    columns={[
      { title: 'Viewer Name', dataIndex: 'name', key: 'name' },
      {
        title: 'Viewer Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Viewed At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (dom: React.ReactNode) =>
          typeof dom === 'string' ? moment(dom).format('ddd DD MMM, YYYY hh:mm a') : dom,
      },
    ]}
    className="custom-expanded-row-content"
    dataSource={record.UserViews}
    pagination={false}
    showSorterTooltip={false}
    rowKey="id"
    toolBarRender={false}
    search={false}
    size='small'
  />
);

interface IAllViewedTableProps {
  size: 'small' | 'middle' | 'large';
}

const AllViewedTable = ({ size }: IAllViewedTableProps) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  const handleExpand = (expanded: boolean, record: IAllViewed) => {
    const uniqueKey = `${record.id}-${record.createdAt}`;

    setExpandedRowKeys((prevExpandedRowKeys) =>
      expanded
        ? [...prevExpandedRowKeys, uniqueKey]
        : prevExpandedRowKeys.filter((key) => key !== uniqueKey)
    );
  };

  const columns: ProColumns<IAllViewed>[] = [
    {
      title: 'Report Name',
      dataIndex: 'name',
      sorter: false,
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      render: (dom: React.ReactNode) =>
        typeof dom === 'string' ? moment(dom).format('ddd DD MMM, YYYY hh:mm a') : dom,
      sorter: false,
    },
    {
      title: 'Total Views',
      dataIndex: 'UserViews',
      render: (_: React.ReactNode, entity) => entity.UserViews.length,
      sorter: false,
    },
  ];

  const expandable = {
    expandedRowKeys,
    onExpand: handleExpand,
    // eslint-disable-next-line react/no-unstable-nested-components
    expandedRowRender: (record: IAllViewed) => <ExpandedRow record={record} />,
  };

  return (
    <Table<IAllViewed>
      size={size}
      search={false}
      columns={columns}
      expandable={expandable}
      showSorterTooltip={false}
      toolBarRender={false}
      pagination={false}
      dataSource={ALL_VIEWED_REPORTS}
      rowKey={(record) => `${record.id}-${record.createdAt}`}
    />
  );
};

export default AllViewedTable;
