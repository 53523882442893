import { CSSProperties } from 'react';

const baseReportSectionStyle: CSSProperties = {
    border: '1px solid #F0F5FF',
    borderRadius: '12px',
};

export const getReportSectionStyle = (name: string): CSSProperties => name === 'welcome_message' ?
    { ...baseReportSectionStyle, maxWidth: '100%' } : baseReportSectionStyle

export const styleEclipses: CSSProperties = {
    width: '100%',
    boxSizing: 'border-box',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
};

export const tooltipStyle: CSSProperties = {
    backgroundColor: '#f5f5f5',
};

export const COLORS = {
    White: '#ffffff',
    BorderGrey: "#a5b5d4"
};

export const CANDLE_CHART_COLORS = {
    backgroundColor: 'white',
    lineColor: '#2962FF',
    areaTopColor: '#2962FF',
    areaBottomColor: 'rgba(41, 98, 255, 0.28)',
    textColor: 'black'
};