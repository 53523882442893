import Icon from '@ant-design/icons';
import React, { ReactNode } from 'react';
import { Link, RouteProps } from 'react-router-dom';
import BreakingNews from '../pages/breakingNews';
import ContentScripts from '../pages/contentScripts';
import ContentScriptDetails from '../pages/contentScripts/details';
import Home from '../pages/home';
import { Add, BarChart, Bell, House, NewsSvg, Note, ReferalSvg, Report, User } from '../components/Common/Icon';
import Activities from '../pages/home/activities';
import RecentReferrals from '../pages/referrals';
import RecentlyViewed from '../pages/home/recentlyViewed';
import MarketRates from '../pages/marketRates';
import Notifications from '../pages/notifications';
import Profile from '../pages/profile';
import Reports from '../pages/reports';
import ReportCreate from '../pages/reports/create';
import ReportDetails from '../pages/reports/details';
import ReportEdit from '../pages/reports/edit';
import UploadQuote from '../pages/reports/quoteUpload';
import ActivitiesByReport from '../pages/home/activities/details';

export interface IRoute {
  bind: RouteProps;
  name?: string;
  icon?: ReactNode;
  parent?: IRoute;
  hidden?: boolean;
  onClick?: () => void;
  children?: IRoute[];
  privateRoute?: boolean;
  isSubscriptionNeeded?: boolean;
  suffix?: JSX.Element;
  isEssential?: boolean;
}

export const sideMenuRoutes: IRoute[] = [
  {
    bind: {
      path: '/home',
      element: <Home />,
    },
    isEssential: true,
    privateRoute: false,
    icon: <Icon component={House} />,
    name: 'Home',
    children: [
      {
        bind: {
          path: '/viewed',
          element: <RecentlyViewed />,
        },
        privateRoute: false,
        hidden: true,
      },
      {
        bind: {
          path: '/activities',
          element: <Activities />,
        },
        privateRoute: false,
        hidden: true,
      },
    ],
  },
  {
    isEssential: true,
    bind: {
      path: '/reports',
      element: <Reports />,
    },
    privateRoute: false,
    icon: <Icon component={Report} />,
    name: 'Report Builder',
    suffix: (
      <Link to="/reports/create">
        <Icon component={Add} className="fs-125" />
      </Link>
    ),
    children: [
      {
        bind: {
          path: '/create',
          element: <ReportCreate />,
        },
        privateRoute: false,
        hidden: true,
      },
      {
        bind: {
          path: '/:id',
          element: <ReportDetails />,
        },
        privateRoute: false,
        hidden: true,
      },
      {
        bind: {
          path: '/:id/activities',
          element: <ActivitiesByReport />,
        },
        privateRoute: false,
        hidden: true,
      },
      {
        bind: {
          path: '/:id/preview',
          element: <ReportDetails />,
        },
        privateRoute: false,
        hidden: true,
      },
      {
        bind: {
          path: '/:id/edit',
          element: <ReportEdit />,
        },
        privateRoute: false,
        hidden: true,
      },
      {
        bind: {
          path: '/:id/upload-quote',
          element: <UploadQuote />,
        },
        privateRoute: false,
        hidden: true,
      },
    ],
  },
  {
    bind: {
      path: '/breaking-news',
      element: <BreakingNews />,
    },
    isEssential: true,
    privateRoute: false,
    icon: <Icon component={Report} />,
    name: 'Breaking News',
  },
  {
    bind: {
      path: '/content-scripts',
      element: <ContentScripts />,
    },
    privateRoute: false,
    icon: <Icon component={Note} />,
    name: 'Content Scripts',
    isEssential: true,
    children: [
      {
        bind: {
          path: '/create',
          element: <ContentScriptDetails />,
        },
        privateRoute: false,
        hidden: true,
      },
      {
        bind: {
          path: '/:id',
          element: <ContentScriptDetails />,
        },
        privateRoute: false,
        hidden: true,
      },
    ],
  },
  {
    bind: {
      path: '/market-rates',
      element: <MarketRates />,
    },
    isEssential: true,
    privateRoute: false,
    icon: <Icon component={BarChart} />,
    name: 'Markets',
  },
  {
    isEssential: true,
    bind: {
      path: '/profile',
      element: <Profile />,
    },
    privateRoute: false,
    icon: <Icon component={User} />,
    name: 'My Profile',
  },
  {
    isEssential: true,
    bind: {
      path: '/notifications',
      element: <Notifications />,
    },
    privateRoute: false,
    icon: <Icon component={Bell} />,
    name: 'Notifications',
  },
  {
    name: 'Recent Referrals',
    isEssential: true,
    bind: {
      path: '/referrals',
      element: <RecentReferrals />,
    },
    icon: <Icon component={ReferalSvg} />,
    privateRoute: true,
    hidden: false,
  },
];
