import { Col, Row, Select, Space, Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { useEffect, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useContextReports } from '../../../../../context/reports';
import { IReportLoan } from '../../../../../hooks/reports';
import { commafy } from '../../../../../utils/text';
import CustomInput from '../../../../Common/CustomInput';
import LinearCalendar from '../../../../Common/LinearCalendar';
import TextWithLabel from '../../../../Common/TextWithLabel';
import { CLOSING_COST_EVENTS } from '../../constants';
import { IEvent } from '../../types';
import ReportSection from '../Section';
import ClosingCostTable from './Table';
import MovingTo from '../../../../Common/MovingTo';

interface IClosingCost {
  provided?: DraggableProvided;
}

const ClosingCost = ({ provided }: IClosingCost) => {
  const name = 'closingCostsEstimates';
  const { isBorrower, handleLoansUpdate } = useContextReports();
  const form = useFormInstance();
  const [events, setEvents] = useState<IEvent[]>(CLOSING_COST_EVENTS);
  const [loanKey, setLoanKey] = useState<number>(0);
  const movingTo = useWatch(['movingTo', name], form);
  const loans = useWatch('loans', form);
  const isSectionActive = useWatch(['reportView', name, 'active'], form);
  const maxDays = useWatch(['loans', loanKey, 'close_of_escrow_day'], form);
  const loan = useWatch(['loans', loanKey], form);
  // const updateClosingCost = useClosingCostUpdate()

  useEffect(() => {
    if (!movingTo || !loans || !isSectionActive) return;

    const index = loans.findIndex((item: IReportLoan) => item.id === movingTo);

    if (index >= 0) {
      setLoanKey(index);

      const newEvents = events.map((event) => ({
        ...event,
        ...(loans[loanKey][`${event.name}_day`] ? { day: loans[loanKey][`${event.name}_day`] } : {}),
        label: loans[loanKey][`${event.name}_price`] ? `$${commafy(loans[loanKey][`${event.name}_price`])}` : '',
      }));

      setEvents(newEvents);
    }
  }, [movingTo, loans, isSectionActive, loanKey]);

  return (
    <ReportSection
      id={name}
      name={name}
      title="Closing costs"
      description="Here's a breakdown of your estimated closing costs,
       along with the timeline for when each amount is typically due."
      provided={provided}
    >
      <div className="flex-col gap-20">
        <Row gutter={[16, 16]} style={{ padding: '5px' }} justify="space-between">
          <Col xs={24} xl={16}>
            <Space size={72} style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', padding: '5px' }}>
              <TextWithLabel
                label="Total cost"
                value={`$${commafy(Number(loan?.closingCosts?.value) + Number(loan?.downPayment?.value) || 0)}`}
                identifier={['loans', loanKey, 'totalCost']}
              />
              <TextWithLabel label="Total days" value={`${maxDays || 0} Days`} />
            </Space>
          </Col>
          <Col xs={24} xl={8}>
            <MovingTo name={name} />
          </Col>
        </Row>
        <div
          style={{
            width: '100%',
            paddingLeft: '20px',
            paddingRight: '20px',
            marginBottom: '20px',
          }}
        >
          <LinearCalendar eventType="simple" maxDays={60} events={events} />
        </div>
        <div className="d-m-none">
          <ClosingCostTable events={events} loanKey={loanKey} loan={loan} />
        </div>
        <div className="d-lg-none d-m-flex flex-m-col gap-20">
          {events.map((event) => (
            <div key={event.key} className="flex-col gap-12">
              <div className="flex-row flex-align-baseline gap-4">
                <div style={{ width: 8, height: 8, borderRadius: '50%', background: event.color, flex: '0 0 8px' }} />
                <Typography.Text>{event.description}</Typography.Text>
              </div>

              <div className="flex-row flex-align-center gap-4">
                <div className="w-m-min-100">
                  {isBorrower ? (
                    <Typography>{event.day}</Typography>
                  ) : (
                    <FormItem name={['loans', loanKey, `${event.name}_day`]} className="table-form-item">
                      <Select
                        size="large"
                        defaultValue={event.day}
                        options={Array.from({ length: 60 }, (__, i) => i + 1).map((item) => ({
                          value: item.toString(),
                          label: item,
                        }))}
                      />
                    </FormItem>
                  )}
                </div>
                <CustomInput
                  formItemProps={{
                    className: 'm-0 label-gray w-m-min-100',
                    name: ['loans', loanKey, `${event.name}`],
                  }}
                  rowStyles={{ height: 38 }}
                  bordered={!isBorrower && !event.disabled}
                  disabled={isBorrower || event.disabled}
                  prefix="$"
                  value={event.prepareData && event.prepareData(loan)}
                  onBlur={() => handleLoansUpdate?.()}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </ReportSection>
  );
};

ClosingCost.defaultProps = {
  provided: undefined,
};

export default ClosingCost;
