/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import qs from 'querystring';
import { Input, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../../index.module.less';
import { USER_DATA } from '../../../../utils/constantData';

const Header = ({ withSearch }: { withSearch?: boolean }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>();
  const profile = USER_DATA;

  useEffect(() => {
    const query = qs.parse(location.search.replace('?', ''));

    setSearch(query?.search as string);
  }, [location]);

  const handleSearch = (value: string) => {
    const params = value
      ? {
          search: value,
        }
      : undefined;

    navigate({ pathname: location.pathname, search: qs.stringify(params) });
  };

  const navigateToProfile = () => {
    navigate('/profile');
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <div>
          {withSearch && (
            <Input.Search
              className="search-input"
              allowClear
              name="search"
              size="large"
              placeholder="Search"
              value={search}
              onSearch={handleSearch}
              onChange={(e) => setSearch(e.target.value)}
            />
          )}
        </div>
        {profile && (
          <div className={styles.userBox}>
            <Typography.Title level={3} className={styles.userName}>
              {profile?.firstName} {profile?.lastName}
            </Typography.Title>
            <img
              className={styles.userAvatar}
              src={profile?.avatar || '/avatar-placeholder.png'}
              alt="Avatar"
              onClick={navigateToProfile}
            />
          </div>
        )}
      </div>
    </header>
  );
};

Header.defaultProps = {
  withSearch: false,
};

export default Header;
