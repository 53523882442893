/* eslint-disable @typescript-eslint/ban-ts-comment */
import Icon from '@ant-design/icons';
import { Button, Input, Typography } from 'antd';
import { useEffect, useState } from 'react';
import Form from 'antd/es/form';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment-timezone';
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { required } from '../../../../utils/inputRules';
import { Back } from '../../../Common/Icon';
import styles from '../index.module.less';
import { editorModuleReadOnly } from '../../Reports/Sections/WelcomeText';
import { IScript, useScripts } from '../../../../hooks/scripts';
import Loading from '../../../Common/Loading';

import 'react-quill/dist/quill.snow.css';
import './style.less';
import { useContextNotifications } from '../../../../context/notifications';

interface Script {
  description: string;
  category: string;
  text: string;
}

const Details = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { id: scriptId } = useParams();
  const getScripts = useScripts();
  const [script, setScript] = useState<IScript | null>();
  const [initialData, setInitialData] = useState<Script | null>(null);
  const { openNotification } = useContextNotifications();

  useEffect(() => {
    getScripts
      .fetch()
      .then((res) => res?.data.find((item) => item.id === Number(scriptId)))
      .then((foundScript) => {
        setScript(foundScript);
        if (foundScript) {
          setInitialData({
            description: foundScript.description,
            category: foundScript.Category?.name,
            text: foundScript.text,
          });
        }
      })
      .catch((error) => {
        openNotification?.({
          message: `Error while Getting Scripts: ${error}`,
          type: 'error',
        });
      });
  }, [scriptId]);

  const handleBack = () => {
    navigate('/content-scripts');
  };

  return (
    <div className="flex-col gap-24 h-100">
      <Loading visible={getScripts.loading} absolute />
      <div className="flex-row flex-align-center gap-4">
        <Button type="ghost" icon={<Icon component={Back} style={{ fontSize: '1.25em' }} />} onClick={handleBack} />
      </div>
      {script && initialData && (
        <div className="flex-1">
          <Form form={form} initialValues={initialData}>
            <div className="flex-col flex-align-center flex-1">
              <div className={styles.scriptDetails}>
                <Form.Item name="description" rules={required('script name')} className="table-form-item flex-1">
                  <Input.TextArea
                    autoSize={{ minRows: 1, maxRows: 3 }}
                    className="title-input fs-2 fw-600 p-0"
                    placeholder="Enter script title"
                    disabled
                  />
                </Form.Item>
                <div className="flex-row gap-12 flex-align-center">
                  {script?.createdAt && (
                    <Typography.Text>{moment(script.createdAt).format('MMM DD, YYYY')}</Typography.Text>
                  )}
                  <Typography.Text className="color-gray fs-0875 uppercase fw-500 ws-nowrap m-l-auto">
                    {script?.Category?.name}
                  </Typography.Text>
                </div>
                <div className="custom-quill-editor">
                  <ReactQuill
                    theme="snow"
                    value={(script && script.text) || ''}
                    modules={editorModuleReadOnly}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default Details;
