import Intercom from 'react-intercom';

const IntercomWidget = () => (
    <Intercom
        appID={process.env.REACT_APP_INTERCOM_APP_ID as string}
        created_at={Date.now()}
    />
);

export default IntercomWidget;
