import Icon from '@ant-design/icons';
import { Button, Space, Switch, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Stripe from 'stripe';
import ContentCard from '../../Common/ContentCard';
import { Back } from '../../Common/Icon';
import PlansDetails from '../../Common/PlansDetails';
import { PLAN_DETAILS, USER_DATA } from '../../../utils/constantData';

const Plans = () => {
  const navigate = useNavigate();

  const [plans, setPlans] = useState<Stripe.Plan[]>();
  const [isYearly, setIsYearly] = useState<boolean>(false);
  const selectedPlanId = USER_DATA.planId;
  const [isToggled, setIsToggled] = useState<boolean>(false);

  useEffect(() => {
    const newPlans: any[] = Object?.values(isYearly ? PLAN_DETAILS.year : PLAN_DETAILS.month)?.sort(
      (a, b) => (a.amount || 0) - (b.amount || 0)
    );

    setPlans(newPlans);

    const userPlan = newPlans?.find((plan: any) => plan.id === USER_DATA?.planId);

    if (!isToggled) {
      if (userPlan?.interval === 'year' || !userPlan) {
        setIsYearly(true);
      } else {
        setIsYearly(false);
      }
    }
  }, [isYearly]);

  const handleBack = () => {
    navigate({ pathname: '/profile', search: 'tab=payment' });
  };

  const toggleSwitch = () => {
    setIsYearly(!isYearly);
    setIsToggled(true);
  };

  return (
    <section>
      <Space direction="vertical" size={32}>
        <div className="flex-row flex-align-center flex-justify-space-between gap-12">
          <div className="flex-row flex-align-center gap-4">
            <Button type="ghost" icon={<Icon component={Back} style={{ fontSize: '1.25em' }} />} onClick={handleBack} />
            <Typography.Title>Plan</Typography.Title>
          </div>
          <div className="flex-row flex-align-center gap-12">
            <Switch onChange={toggleSwitch} checked={isYearly} defaultChecked={isYearly} />
            <Typography.Text className="fw-600 color-gray">Yearly</Typography.Text>
            <div
              style={{
                padding: '2px 10px',
                color: 'var(--color-blue)',
                border: '1px solid var(--color-blue)',
                borderRadius: 4,
              }}
            >
              20% OFF
            </div>
          </div>
        </div>

        <Space direction="vertical" size={12}>
          <Typography.Title level={3}>All Plans</Typography.Title>
          <ContentCard>
            <PlansDetails plans={plans as any} selectedPlan={selectedPlanId} />
          </ContentCard>
        </Space>
      </Space>
    </section>
  );
};

export default Plans;
