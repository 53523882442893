import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Main';
import Content from '../../../components/Pages/Reports/Edit';
import { displayName } from '../../../config';
import ReportsProvider from '../../../context/reports';
import { useReportCreate } from '../../../hooks/reports';
import { JsonResult } from '../../../types';
import Loading from '../../../components/Common/Loading';
import { fetchIp } from '../../../utils';

const ReportCreate = (): JSX.Element => {
  document.title = `${displayName}: Report Create`;
  const reportCreate = useReportCreate();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const loanColor: JsonResult = {};

    fetchIp().then((data) => {
      reportCreate
        .fetch({ name: '', status: 'draft', loanColor, ip: data.ip })
        .then((res) => {
          if (!res?.data.id) return;

          const queryParams = new URLSearchParams(location.search);
          const zpidParam = queryParams.get('zpid');
          let redirectUrl = `/reports/${res.data.id}/edit`;

          if (zpidParam) {
            redirectUrl = `/reports/${res.data.id}/edit?zpid=${zpidParam}`;
          }

          navigate(redirectUrl);
        }).catch(() => {
          navigate(`/reports`);
        })
        .finally(() => {
          setLoading(false);
        });
    })
  }, []);

  return (
    <Layout>
      <Loading absolute visible={loading} />
      <ReportsProvider value={{ isBorrower: false }}>
        <Content isLoading={loading} />
      </ReportsProvider>
    </Layout>
  );
};

export default ReportCreate;
