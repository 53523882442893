import React from 'react';
import { BrowserRouter, Navigate, Route as PublicRoute, Routes } from 'react-router-dom';
import Plans from '../pages/plans';
import Referral from '../pages/referral';
import { IRoute, sideMenuRoutes } from './sideMenu';

import NotFound from '../pages/notFound';
import { USER_DATA } from '../utils/constantData';

export const createChildrenRoutes = (initialRoutes: IRoute[]): IRoute[] => {
  const list: IRoute[] = [...initialRoutes];

  function addChildren(route: IRoute, parent?: IRoute): void {
    let newRoute = route;

    if (parent) {
      newRoute = {
        ...route,
        parent,
        bind: {
          ...route.bind,
          path: `${parent.bind.path}${route.bind.path}`,
        },
      };
      list.push(newRoute);
    }

    if (newRoute.children) {
      newRoute.children.forEach((child) => addChildren(child, newRoute));
    }
  }

  list.forEach((route) => addChildren(route, undefined));

  return list;
};

const Route = (): JSX.Element => {
  const profile = USER_DATA;

  const keys = Object.keys(profile && profile.permissions ? JSON.parse(profile?.permissions) : {});

  const filteredSideMenuRoutes =
    profile && profile.type === 'admin'
      ? sideMenuRoutes
      : sideMenuRoutes.filter((route) => route.isEssential || (route.name && keys.includes(route.name)));

  const routes: IRoute[] = createChildrenRoutes([
    {
      bind: {
        path: '/not-found',
        element: <NotFound />,
      },
      name: 'Not Found',
    },
    {
      bind: {
        path: '/plans',
        element: <Plans />,
      },
      name: 'Plans',
    },
    {
      bind: {
        path: '/referral/:id',
        element: <Referral />,
      },
      privateRoute: false,
      name: 'Referral',
    },
    ...filteredSideMenuRoutes,
  ]);

  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route) => (
          <PublicRoute key={route.bind.path} {...route.bind} element={route.bind.element} />
        ))}
        <PublicRoute path="/" element={<Navigate to="/home" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Route;
