import { Col, Row, Space, Typography } from 'antd';
import ContentCard from '../../../Common/ContentCard';
import Tile from './Tile';

const Performance = () => (
  <ContentCard bordered padding={20} height="100%">
    <Space direction="vertical" size={20}>
      <Typography.Title level={2} style={{ color: '#464646' }}>
        Report Statistics
      </Typography.Title>
      <div>
        <Row className="border-bottom">
          <Col span={12} className="border-right">
            <Tile label="Report actions" value={12} performanceValue={4} />
          </Col>
          <Col span={12}>
            <Tile label="Report views" value={15} performanceValue={3} />
          </Col>
        </Row>
        <Row>
          <Col span={12} className="border-right">
            <Tile label="Reports created" value={3} performanceValue={1} />
          </Col>
          <Col span={12}>
            <Tile label="Referrals" value={4} performanceValue={-1} />
          </Col>
        </Row>
      </div>
    </Space>
  </ContentCard>
);

export default Performance;
