import { Button, Form } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useContextNotifications } from '../../../../context/notifications';
import { IReportShareParams, useReportShare, useReportTrackActivities } from '../../../../hooks/reports';
import { JsonResult } from '../../../../types';
import { useContextReports } from '../../../../context/reports';
import Sections from '../Sections';
import Header from '../Sections/Header';
import LoanComparisons from '../Sections/LoanComparisons';
import ShareModelDemo from './ShareReportModalDemo';

import styles from '../index.module.less';

const ReportEdit = ({ isLoading }: { isLoading?: boolean }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { openNotification } = useContextNotifications();
  const {
    form,
    errors,
    isBorrower,
    reportId,
    setReportId,
    loans,
    handleReportUpdate,
  } = useContextReports();
  const reportShare = useReportShare();
  const reportTrackActivities = useReportTrackActivities();
  const [isShareModalOpened, setIsShareModalOpened] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    const isPreview = location.pathname.includes('/preview');

    if (!isBorrower || !id || isPreview) return;

    reportTrackActivities.fetch({ id, type: 'viewed' });
  }, []);

  const loadData = () => {
    if (!id || !setReportId) return;

    setReportId(id);
  };

  useEffect(() => {
    loadData();
  }, [id]);

  const handleSaveAsDraft = () => {
    handleReportUpdate?.();
    handleReportUpdate?.('draft', () => {
      openNotification?.({ message: 'Report has been saved as Draft' });
    });
  };

  const handleShareModalOpen = () => {
    setIsShareModalOpened(true);
  };

  const handleShareModalClose = () => {
    setIsShareModalOpened(false);
  };

  const handleReportShare = (data: JsonResult, handleClose: () => void) => {
    handleClose();

    reportShare.fetch(data as IReportShareParams).then(() => {
      openNotification?.({ message: 'Report shared successfully' });
      navigate('/reports');
    });
  };

  const handleReportGenerate = () => {
    handleShareModalOpen();
    handleReportUpdate?.();
    handleReportUpdate?.('completed', () => {
      handleShareModalOpen();
    });
  };

  const seePreview = () => {
    window.location.href = `${window.location.origin}/reports/${id || reportId}/preview`;
  };

  useEffect(() => {
    if (!errors) return;

    errors.forEach((error) => {
      openNotification?.({ type: 'error', message: error });
    });
  }, [errors]);

  return (
    <section id="reportToPdf" className={styles.container}>
      <ShareModelDemo
        open={isShareModalOpened}
        onCancel={handleShareModalClose}
        onOk={handleReportShare}
      />
      <div className={styles.content} >
        {isBorrower && <Header />}
        <Form layout="vertical" form={form}>
          <div className="flex-col gap-20">
            <LoanComparisons headerLess={isBorrower} />
            {!!loans?.filter((loan) => Boolean(loan.hidden) === false)?.length && <Sections />}
          </div>
        </Form>
      </div>
      {!!loans?.length && !isBorrower && (
        <div className="flex-row gap-8 flex-justify-end" style={{ flexWrap: 'wrap' }}>
          <Button className="btn-default-dark-blue" type="default" size="middle" onClick={seePreview}>
            Preview
          </Button>
          <Button className="btn-default-dark-blue" type="default" size="middle" onClick={handleSaveAsDraft}>
            Save as Draft
          </Button>
          <Button type="primary" size="middle" onClick={handleReportGenerate}>
              Share Report
          </Button>
        </div>
      )}
    </section>
  );
};

ReportEdit.defaultProps = {
  isLoading: false
}

export default ReportEdit;
