import React, { Dispatch, KeyboardEventHandler, SetStateAction, useCallback, useEffect, useState } from 'react';
import Icon from '@ant-design/icons';
import { Button, Select, Typography } from 'antd';
import Form from 'antd/es/form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { debounce } from 'chart.js/helpers';
import { IReportProperty, useReportLocationSearch, useReportPropertyDetails } from '../../../../../../../hooks/reports';
import { Location, Plus, Close } from '../../../../../../Common/Icon';
import Loading from '../../../../../../Common/Loading';
import { DEFAULT_COLUMN } from '../../../../constants';
import styles from '../../index.module.less';
import { useContextReports } from '../../../../../../../context/reports';
import { fetchIp } from '../../../../../../../utils';
import { commafy, toFixed, toFloat } from '../../../../../../../utils/text';
import { calculateMonthlyPayment } from '../../../../../../../utils/prepareData';

interface IControlsCol {
  typeNewColState: [value: 'area' | 'location' | null, setValue: Dispatch<SetStateAction<'area' | 'location' | null>>];
  handleClose: () => void;
  loansLength: number;
  zpidData: string | null;
}

interface IAddNewCol {
  address?: string | undefined;
  zipCode?: string;
  zpid?: string | undefined;
}

const ControlsCol = ({ typeNewColState, handleClose, loansLength, zpidData }: IControlsCol) => {
  const defaultColumn = { ...DEFAULT_COLUMN };
  const form = useFormInstance();
  const { handleLoansUpdate, getLoans } = useContextReports();
  const [typeNewCol, setTypeNewCol] = typeNewColState;
  const [searchValue, setSearchValue] = useState<string>('');
  const [properties, setProperties] = useState<IReportProperty | IReportProperty[] | null>();
  const reportLocationSearch = useReportLocationSearch();
  const reportPropertyDetails = useReportPropertyDetails();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (zpidData && !loansLength)
      handleAddNewColumn({ zpid: zpidData })
  }, [])

  useEffect(() => {
    if (reportLocationSearch.error || reportPropertyDetails.error) {
      setSearchValue('');
    }
  }, [reportLocationSearch.error, reportPropertyDetails.error]);

  const handleAddNewColumn = ({ address, zipCode, zpid }: IAddNewCol) => {
    if (isLoading) return;

    let loanAddress = '';

    const isEmptyAddress = !address || address.split(',').some((item) => item.trim() === 'undefined');
    const loans = form.getFieldValue('loans');

    if (zpid) {
      fetchIp().then((ipData) => {
        reportPropertyDetails.fetch({ zpid, ip: ipData.ip }).then((res) => {
          if (!res?.data) return;

          const {
            purchasePrice,
            propertyTaxes,
            hoaDues,
            supplyMentalTaxBill,
            rentValue,
            suplimentalData,
            priceStatus,
            taxRate,
            addressString
          } = res.data;

          const downPayment = (toFloat(purchasePrice || 0) * toFloat(20)) / 100;
          const homeInsurance = (toFloat(purchasePrice || 0) * (0.25 / 100)) / 12;
          const loanAmount = toFloat(purchasePrice || 0  ) - downPayment
          const interestRate = '6.5'
          const principalInterest = calculateMonthlyPayment(interestRate, loanAmount.toString())

          if (isEmptyAddress) {
            loanAddress = addressString || '';
          } else {
            loanAddress = address || '';
          }

          const newColumns = [
            ...(loans || []),
            {
              ...defaultColumn,
              address: loanAddress,
              zipCode,
              zpid,
              suplimentalData,
              purchasePrice: {
                value: purchasePrice,
              },
              propertyTaxes: {
                value: propertyTaxes,
              },
              realEstateTaxes:{
                value: propertyTaxes,
              },
              hoaDues: {
                value: hoaDues,
              },
              supplyTaxBill: {
                value: supplyMentalTaxBill,
              },
              rentalValue: {
                value: rentValue,
              },
              priceStatus: {
                value: priceStatus,
              },
              propertyTaxesPercentage: {
                value: taxRate
              },
              downPayment: {
                value: commafy(downPayment)
              },
              downPaymentPercentage: {
                value: '20'
              },
              interestRate: {
                value: interestRate
              },
              homeInsurance: {
                value: toFixed(homeInsurance, 2)
              },
              loanAmount: {
                value: commafy(toFloat(purchasePrice || 0) - downPayment)
              },
              principalInterest: {
                value: principalInterest
              }
            }
          ];

          form.setFieldValue('loans', newColumns);
          handleLoansUpdate?.(undefined, false)
          getLoans?.()
          setTypeNewCol(null);
        });
      });
    } else {
      const newColumns = [...(loans || []),
      { ...defaultColumn, address: isEmptyAddress ? undefined : address, zipCode }];

      form.setFieldValue('loans', newColumns);
      setTypeNewCol(null);
      handleLoansUpdate?.();
    }
  };

  const debouncedSearch = useCallback(
    debounce(([location]) => {
      reportLocationSearch
        .fetch({ location })
        .then((res) => setProperties(res?.data.results))
        .then(() => setIsLoading(false));
    }, 1000),
    []
  );

  const handleSearch = (value: string) => {
    setIsLoading(true);

    setSearchValue(value ?? '');

    if (!value) return;

    setProperties(null);

    debouncedSearch(value);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    const target = event.target as HTMLInputElement;

    if (event.key !== 'Enter' || !target.value) return;

    if (isLoading) {
      event.preventDefault();

      return;
    }

    handleAddNewColumn({ address: target.value });
  };

  return (
    <div style={{ flex: '1 1 auto' }} className={styles.tableCol}>
      <Loading visible={reportPropertyDetails.loading || reportLocationSearch.loading} absolute />
      <div className={styles.tableHeadCell}>
        {typeNewCol === 'location' && (
          <Form.Item className="table-form-item">
            <Select
              showSearch
              onChange={(value) => handleAddNewColumn(JSON.parse(value))}
              onSearch={handleSearch}
              onInputKeyDown={handleKeyDown}
              autoClearSearchValue={false}
              popupClassName="select-popup"
              placement="bottomRight"
              loading={reportLocationSearch.loading}
              searchValue={searchValue}
              filterOption={false}
              dropdownStyle={!properties ? { display: 'none' } : {}}
              placeholder="ENTER ADDRESS"
              optionLabelProp="address"
              open={Boolean(searchValue)}
              value={searchValue}
              options={
                Array.isArray(properties)
                  ? properties?.map((item) => ({
                    label: (
                      <div className={styles.optionRow}>
                        <Icon component={Location} />
                        <Typography.Title level={4} className={styles.city}>
                          {item.streetAddress}, {item.city}, {item.state}, {item.zipcode}
                        </Typography.Title>
                      </div>
                    ),
                    value: JSON.stringify({
                      address: `${item.streetAddress};${item.city}, ${item.state}, ${item.zipcode}`,
                      zipCode: item.zipcode,
                      zpid: item.zpid,
                    }),
                    address: `${item?.streetAddress}, ${item?.city}`,
                  }))
                  : [
                    {
                      label: (
                        <div className={styles.optionRow}>
                          <Icon component={Location} />
                          <Typography.Title level={4} className={styles.city}>
                            {properties?.streetAddress}, {properties?.city}, {properties?.state},{' '}
                            {properties?.zipcode}
                          </Typography.Title>
                        </div>
                      ),
                      value: JSON.stringify({
                        address: `${properties?.streetAddress};${properties?.city}, ${properties?.state},
                          ${properties?.zipcode}`,
                        zipCode: properties?.zipcode,
                        zpid: properties?.zpid,
                      }),
                      address: `${properties?.streetAddress}, ${properties?.city}`,
                    },
                  ]
              }
            />
            <Button type="ghost" size="small" icon={<Icon component={Plus} />} onClick={() => handleAddNewColumn({})}>
              add blank column
            </Button>
            <Button type="ghost" size="small" icon={<Icon component={Close} />} onClick={handleClose}>
              {' '}
              close
            </Button>
          </Form.Item>
        )}
      </div>
    </div>
  );
};

export default ControlsCol;