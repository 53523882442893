import Icon from '@ant-design/icons';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Button, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { Info, Logo, LogOut } from '../../../Common/Icon';

import MenuCommon from './Menu';

import styles from '../../index.module.less';
import { USER_DATA } from '../../../../utils/constantData';

const SiderCommon = () => {
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const profile = USER_DATA;

  return (
    <div className={styles.siderContent}>
      <div className={clsx('flex-col w-100 d-m-flex d-lg-none', { [styles.opened]: isMenuOpened })}>
        <div className={styles.siderRow}>
          <Link to="/" className={styles.logo}>
            <Logo />
          </Link>
          <div
            className={clsx(styles.hamburger, { [styles.checked]: isMenuOpened })}
            onClick={() => setIsMenuOpened((prev) => !prev)}
          >
            <div className={styles.line} />
            <div className={styles.line} />
            <div className={styles.line} />
          </div>
        </div>
        <div className={styles.menuWrapper}>
          <div className={styles.menuContainer}>
            <div className={styles.menuContent}>
              <MenuCommon />
            </div>
            <Button type="ghost" icon={<Icon component={Info} />} size="large" className={styles.menuButton}>
              Tutorial
            </Button>
            <Button
              type="ghost"
              icon={<Icon component={LogOut} />}
              size="large"
              // onClick={handleLogout}
              className={styles.menuButton}
            >
              Log Out
            </Button>
          </div>
          {profile && (
            <div className={styles.userBox}>
              <Typography.Title level={3} className={styles.userName}>
                {profile?.firstName} {profile?.lastName}
              </Typography.Title>
              <img className={styles.userAvatar} src={profile?.avatar || '/avatar-placeholder.png'} alt="Avatar" />
            </div>
          )}
        </div>
      </div>
      <div className={clsx(styles.sider, 'd-lg-grid', 'd-m-none')}>
        <Link to="/" className={styles.logo}>
          <Logo />
        </Link>
        <MenuCommon />
        <Button
          type="ghost"
          icon={<Icon component={Info} />}
          size="large"
          href="https://www.youtube.com/@Mortgagemaker"
          target="_blank"
        >
          Tutorial
        </Button>
      </div>
    </div>
  );
};

export default SiderCommon;
