import Icon from '@ant-design/icons';
import { Image, Button, Space, Upload, UploadFile, Typography } from 'antd';
import ImgCrop from 'antd-img-crop';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { CropperProps } from 'react-easy-crop';
import { Delete } from '../Icon';

import styles from './index.module.less';

interface IImageUpload {
  url?: string | null;
  label: string;
  cropShape: 'rect' | 'round';
  fileName: string;
  placeholder: string;
}
const ImageUpload = ({ url, label, fileName, cropShape, placeholder }: IImageUpload) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [filePreview, setFilePreview] = useState<string>('');

  useEffect(() => {
    setFilePreview(url || '');
    setFileList([{ name: 'avatar.png', uid: '1' }]);
  }, [url]);

  const handleUpload = () => true;

  const cropSize = cropShape === 'rect' ? { width: 470, height: 150 } : { width: 300, height: 300 };

  return (
    <Space direction="vertical">
      <Typography.Title level={3}>{label}</Typography.Title>
      <Space size={12} style={{ alignItems: 'center' }}>
        <div className={clsx(styles.imageBox, styles[cropShape])}>
          <Image
            style={{ objectFit: 'contain' }}
            src={filePreview || placeholder}
            alt="User Avatar"
            height={100}
            width={100}
          />
        </div>
        <Space direction="vertical" size={0}>
          <ImgCrop
            fillColor="transparent"
            cropShape={cropShape}
            cropperProps={
              {
                restrictPosition: false,
                cropSize,
              } as CropperProps
            }
            minZoom={0}
            quality={1}
            aspect={cropShape === 'rect' ? 16 / 9 : 1}
          >
            <Upload
              customRequest={handleUpload}
              fileList={fileList}
              accept="image/*"
              maxCount={1}
              multiple={false}
              showUploadList={false}
            >
              <Button disabled type="ghost" className={styles.uploadButton}>
                Change photo
              </Button>
            </Upload>
          </ImgCrop>
          {filePreview && (
            <Space size={40}>
              <Typography.Title level={4} className="color-gray">
                {fileName}
              </Typography.Title>

              <Button type="ghost" icon={<Icon component={Delete} style={{ fontSize: 20 }} />} />
            </Space>
          )}
        </Space>
      </Space>
    </Space>
  );
};

ImageUpload.defaultProps = {
  url: '',
};

export default ImageUpload;
