import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { JsonResult } from '../../../../types';
import ContentCard from '../../../Common/ContentCard';

interface ITile {
  script: JsonResult;
  blurred: boolean;
}

const Tile = ({ script, blurred }: ITile) => (
  <ContentCard padding={12} bordered height="100%" blurred={blurred}>
    {blurred ? (
      <div className="flex-col gap-4">
        <div className="flex-row flex-align-center gap-12 flex-wrap flex-justify-space-between">
          <Typography.Text className="color-gray fs-0875 uppercase fw-500 ws-nowrap">
            {script.Category.name}
          </Typography.Text>
        </div>
        <Typography.Text className="fs-0875 uppercase fw-500">{script.description}</Typography.Text>
      </div>
    ) : (
      <Link to={`/content-scripts/${script.id}`}>
        <div className="flex-col gap-4">
          <div className="flex-row flex-align-center gap-12 flex-wrap flex-justify-space-between">
            <Typography.Text className="color-gray fs-0875 uppercase fw-500 ws-nowrap">
              {script.Category.name}
            </Typography.Text>
          </div>
          <Typography.Text className="fs-0875 uppercase fw-500">{script.description}</Typography.Text>
        </div>
      </Link>
    )}
  </ContentCard>
);

export default Tile;
