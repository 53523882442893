import { Menu, MenuProps, Typography } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { sideMenuRoutes } from '../../../../../routes/sideMenu';

type MenuItem = Required<MenuProps>['items'][number];

function MenuCommon(): JSX.Element | null {
  const { pathname } = useLocation();

  const [key, setKey] = useState('');

  useEffect(() => {
    setKey(pathname.split('/').find((item) => !!item) || '');
  }, [pathname]);

  if (!key) {
    return null;
  }

  const getItem = (
    label: React.ReactNode,
    itemKey?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[],
    disabled?: boolean
  ): MenuItem =>
    ({
      key: itemKey,
      icon,
      children,
      label,
      disabled,
    } as MenuItem);

  return (
    <Menu
      mode="inline"
      selectedKeys={[pathname, `/${key}`]}
      defaultOpenKeys={[`/${key}`]}
      defaultSelectedKeys={[pathname, `/${key}`]}
      items={sideMenuRoutes.map((route) => {
        const children = route.children
          ?.filter(({ hidden }) => !hidden)
          ?.map((subRoute) =>
            getItem(
              subRoute.isSubscriptionNeeded ? (
                <Typography className="color-gray">{subRoute.name}</Typography>
              ) : (
                <Link aria-disabled to={`${route.bind.path}${subRoute.bind.path}`}>
                  {subRoute.name}
                </Link>
              ),
              `${route.bind.path}${subRoute.bind.path}`,
              subRoute.icon
            )
          );

        return getItem(
          <div className="flex-row flex-justify-space-between flex-align-center">
            <Link className="flex-1" to={route.bind.path || '/'}>
              {route.name}
            </Link>

            {!!route?.suffix && route.suffix}
          </div>,
          route.bind.path,
          route.icon,
          children?.length ? children : undefined,
          route.isSubscriptionNeeded
        );
      })}
    />
  );
}

export default MenuCommon;
