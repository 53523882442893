import { Button, Typography } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ContentCard from '../../../../Common/ContentCard';
import { formatPlanData } from '../../../../Common/PlansDetails/PlanItem';

import styles from './index.module.less';
import { PLANID_DATA, SUBCRIPTION_DATA } from '../../../../../utils/constantData';

const CurrentPlan = () => {
  const navigate = useNavigate();
  const planId = PLANID_DATA;
  const [trialDaysLeft, setTrialDaysLeft] = useState<number>();
  const [endDate, setEndDate] = useState<string>();

  useEffect(() => {
    const { current_period_start, current_period_end, plan } = SUBCRIPTION_DATA;

    if (plan?.trial_period_days) {
      setEndDate(moment.unix(current_period_start).add(plan?.trial_period_days, 'days').format('MMM DD, YYYY'));
    } else {
      setEndDate(moment.unix(current_period_end).format('MMM DD, YYYY'));
    }
    if (plan?.trial_period_days) {
      setTrialDaysLeft(moment.unix(current_period_start).add(plan.trial_period_days, 'days').diff(moment(), 'd'));
    }
  }, []);

  const handleUpdatePlan = () => {
    navigate('/plans');
  };

  return (
    <ContentCard
      bordered
      padding={20}
      height="100%"
      footer={
        <Button type="default" className="btn-default-dark-blue" size="large" onClick={handleUpdatePlan}>
          Upgrade plan
        </Button>
      }
    >
      <div className={styles.content}>
        <div className={styles.textRow}>
          <div>
            <Typography.Title level={3}>
              {planId.nickname} {trialDaysLeft !== undefined && `(${trialDaysLeft} days left)`}
            </Typography.Title>
            {endDate && <Typography className="color-gray">Next renewal on {endDate}</Typography>}
          </div>
          {planId.amount && planId.interval && (
            <Typography.Title className={styles.price}>{formatPlanData(planId)}</Typography.Title>
          )}
        </div>
      </div>
    </ContentCard>
  );
};

export default CurrentPlan;
