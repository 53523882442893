import React from 'react';
import { Typography } from 'antd';
import MarketRatesChart from '../../Common/MarketRatesChart';
import { MARKET_RATE_10, MARKET_RATE_30 } from '../../../utils/constantData';

const MarketRates = () => {
  const currentMarketRates = MARKET_RATE_30;
  const currentTreasury = MARKET_RATE_10;

  return (
    <div className="flex-col gap-12">
      <Typography.Title level={1}>Markets</Typography.Title>
      <div className="flex-col gap-20">
        <MarketRatesChart title="UMBS 30 Year - 5.5% Coupon" data={currentMarketRates} height={300} reversed={false} />
        <MarketRatesChart title="US 10 Year Treasury" data={currentTreasury} reversed height={300} />
      </div>
    </div>
  );
};

export default MarketRates;
