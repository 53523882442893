import { Image, Col, Row, Typography, Checkbox, Form } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import ContentCard from '../../../../Common/ContentCard';
import Section from '../Section';

import styles from './index.module.less';

const ComplianceLogos = () => {
  const form = useFormInstance();
  const activeLogo = useWatch('settings_logo', form) || 'ComplianceLogoType1.png';

  const logos = Array.from({ length: 5 }, (__, i) => `ComplianceLogoType${i + 1}.png`);

  return (
    <Section
      form={form}
      label="Compliance Logos"
      description="Include the appropriate logo on your marketing materials per your state's laws."
    >
      <div className="flex-col gap-20">
        <Typography className="fw-600">Compliance Logos*</Typography>
        <Form.Item name="settings_logo" noStyle />
        <Row gutter={[20, 20]}>
          {logos.map((logo) => (
            <Col key={logo} span={24} lg={12}>
              <ContentCard bordered padding={20}>
                <Checkbox disabled className={styles.checkboxWrapper} checked={activeLogo === logo}>
                  <Image preview={false} style={{ objectFit: 'contain' }} height={100} src={`/${logo}`} />
                </Checkbox>
              </ContentCard>
            </Col>
          ))}
        </Row>
      </div>
    </Section>
  );
};

export default ComplianceLogos;
