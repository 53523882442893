import React, { useEffect, useState } from 'react';
import {  Typography } from 'antd';
import { FrownOutlined } from '@ant-design/icons';
import NewsCard from '../card';
import { INews, useNews } from '../../../../hooks/news';
import { useContextNotifications } from '../../../../context/notifications';
import Loading from '../../../Common/Loading';

import './newsList.less';

const NewsList = () => {
  const [news, setNews] = useState<INews[]>([]);
  const { openNotification } = useContextNotifications();
  const getNews = useNews();

  const fetchNews = (search = '') => {
    getNews
      .fetch({ pageSize: 20, searchQuery: search })
      .then((res) => {
        if (res?.data?.data) {
          const sortedNews = [...res.data.data].sort((a: INews, b: INews) => {
            const dateA = new Date(a.pubDate).getTime();
            const dateB = new Date(b.pubDate).getTime();

            return dateB - dateA;
          });

          setNews(sortedNews);
        }
      })
      .catch((error) => {
        openNotification?.({
          message: `Error while Getting News: ${error}`,
          type: 'error',
        });
      });
  };

  useEffect(() => {
    fetchNews();
  }, []);

  return (
    <>
      <Loading visible={getNews.loading} absolute />
      {news.length === 0 ? (
        !getNews.loading && (
          <div className="not-found-container">
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
              <FrownOutlined />
              <Typography.Title level={4}>No News Found</Typography.Title>
            </div>
            <p>Try adjusting your search or filter to find what you're looking for.</p>
          </div>
        )
      ) : (
        <div className="wrapper" style={{ display: 'grid' }}>
          {news.map((item: INews, index: number) => (
            <NewsCard key={item.id} news={item} blurred={index > 2} />
          ))}
        </div>
      )}
    </>
  );
};

export default NewsList;
