import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Layout } from 'antd';
import { useParams } from 'react-router-dom';
import { useContextReports } from '../../../context/reports';
import Loading from '../../Common/Loading';
import Header from './Header';

import styles from '../index.module.less';
import SiderCommon from './Sider';

const Report = ({ children }:PropsWithChildren) => {
  const { id: reportId } = useParams();
  const { report, loans, settings, setReportId } = useContextReports();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!reportId || !setReportId) return;
    setReportId(reportId);
  }, [reportId]);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [loading]);

  return (
    <Layout className={styles.layout} hasSider>
      {!report || !loans || !settings ? (
        <Loading visible absolute />
      ) : (
        <>
          <Layout.Sider className={styles.siderWrapper}>
            <SiderCommon />
          </Layout.Sider>
          <div className={styles.container}>
            <Header setLoading={setLoading} />
            <div key={Number(loading)} className={styles.content}>
              {children}
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default Report;
