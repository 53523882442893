import enUS from 'antd/lib/locale/en_US';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { store } from './store';
import IntercomWidget from './Widget';

import Routes from './routes';
import NotificationsProvider from './context/notifications';
import Auth, { AuthContext } from './context/auth';

import './App.less';

const App = (): JSX.Element => (
    <Provider store={store}>
      <NotificationsProvider>
        <AuthContext.Provider value={Auth}>
          <ConfigProvider locale={enUS}>
            <IntercomWidget />
            <Routes />
          </ConfigProvider>
        </AuthContext.Provider>
      </NotificationsProvider>
    </Provider>
  );

export default App;
