import { FetchResponse } from '../types';
import { FetchGet, useFetchGet } from './fetch';

export interface ICategory {
  id: number;
  name: string;
  updatedAt: string;
  createdAt: string;
}

export interface IScript {
  id: number;
  text: string;
  description: string;
  categoryId: number;
  updatedAt: string;
  createdAt: string;
  isViewed?: boolean;
  Category: ICategory;
}

export interface IScriptsParams {
  id?: string;
}

export interface IScriptParams {
  id?: number;
  text: string;
  description: string;
  categoryId: number;
}

export const useScripts = (): FetchGet<FetchResponse<IScript[]>, IScriptsParams> =>
  useFetchGet('scripts/all', { autoStart: false, startStateLoading: false, multiple: 'scripts' });
