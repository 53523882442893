import { Button, Card, Divider } from 'antd';
import React, { CSSProperties } from 'react';
import './style.less';

interface IContentCard extends CSSProperties {
  loading?: boolean;
  children: React.ReactNode;
  bordered?: boolean;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  blurred?: boolean;
  chart?: boolean;
  }

const ContentCard = ({ loading, children, bordered, footer, header, blurred, chart, ...styles }: IContentCard) => (

  chart ? (
    <div style={{position:'relative'}}>
    <Button size='large' block type='primary'
      style={{
        position: 'absolute',
        zIndex: 5,
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      onClick={() => { window.open('https://app.mortgagemaker.ai/sign-up', '_blank'); } }>
      Register to see live data
    </Button><Card style={styles} bordered={bordered} loading={loading} className={blurred ? 'blurred' : ''}>
        {header && (
          <>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>{header}</div>
            <Divider />
          </>
        )}
        {children}
        {footer && (
          <>
            <Divider />
            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>{footer}</div>
          </>
        )}
      </Card></div>) : 
  (
  <Card style={styles} bordered={bordered} loading={loading} className={blurred ? 'blurred' : ''}>
    {header && (
      <>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>{header}</div>
        <Divider />
      </>
    )}
    {children}
    {footer && (
      <>
        <Divider />
        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>{footer}</div>
      </>
    )}
  </Card>
  )
);

ContentCard.defaultProps = {
  loading: false,
  bordered: false,
  padding: 0,
  height: 'fit-content',
  footer: undefined,
  header: undefined,
  blurred: false,
  chart: false,
};

export default ContentCard;
