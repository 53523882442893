import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import React from 'react';
import { DragDropContext, Draggable, DraggableProvided, Droppable, DropResult } from 'react-beautiful-dnd';
import { ISettingItem } from '../../../../hooks/users';
import BreakEventPoint from './BreakEvenPoint';
import BuyDownCalculator from './BuyDownCalculator';
import ClosingCost from './ClosingCost';
import ClosingText from './ClosingText';
import HowLongToBuy from './HowLongToBuy';
import InterestSaving from './InterestSaving';
import SavingForIncreasing from './SavingForIncreasing';
import LongTermInvestments from './LongTermInvestments';
import Referral from './Referral';
import RentalAnalysis from './RentalAnalysis';
import RentVsOwn from './RentVsOwn';
import TaxAdvantage from './TaxAdvantage';
import VideoWalk from './Video';
import RefinanceSavings from './RefinanceSavings';
import { useContextReports } from '../../../../context/reports';

const Sections = () => {
  const form = useFormInstance();
  const isBorrower = false;
  const { handleReportSettingsUpdate } = useContextReports();

  const getSection = (name: string, provided?: DraggableProvided) => {
    const SECTIONS: { [key: string]: React.ReactNode } = {
      longTermInvestmentsNumbers: <LongTermInvestments provided={provided} />,
      buyDownCalculator: <BuyDownCalculator provided={provided} />,
      closingCostsEstimates: <ClosingCost provided={provided} />,
      savingForIncreasing: <SavingForIncreasing provided={provided} />,
      taxAdvantage: <TaxAdvantage provided={provided} />,
      breakEvenPoint: <BreakEventPoint provided={provided} />,
      interestSavings: <InterestSaving provided={provided} />,
      rentalAnalysis: <RentalAnalysis provided={provided} />,
      closingText: <ClosingText provided={provided} />,
      referral: <Referral provided={provided} />,
      rentOrOwn: <RentVsOwn provided={provided} />,
      vidoeWalk: <VideoWalk provided={provided} />,
      refinaceSavings: <RefinanceSavings provided={provided} />,
      howLongTakeToBuy: <HowLongToBuy provided={provided} />,
    };

    return SECTIONS[name];
  };

  const updateSettings = (list: ISettingItem[]) => {
    list
      ?.filter((item) => !!getSection(item.name))
      ?.forEach((row, index) => {
        form.setFieldValue(['reportView', row.name], { ...row, order: index + 1 });
      });
  };

  const handleDrop = (droppedItem: DropResult, list: ISettingItem[]) => {
    if (!droppedItem.destination) return;

    const updatedList = [...list];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);

    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

    updateSettings(updatedList);
    handleReportSettingsUpdate?.('reportView');
  };

  const sections = form.getFieldValue('reportView');

  const sectionsList: ISettingItem[] = sections
    ? (Object.values(sections) as ISettingItem[])
      .filter((item) => !!getSection(item.name))
      .filter((item) => !isBorrower || item.active)
    : [];

  return (
    <DragDropContext onDragEnd={(droppedItem) => handleDrop(droppedItem, sectionsList)}>
      <Droppable droppableId="reportView">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided?.innerRef}>
            {sectionsList
              .sort((a, b) => a.order - b.order)
              .map((item, index) => (
                <Draggable key={item.name} draggableId={item.name} index={index}>
                  {(draggableProvided) => {
                    const section = getSection(item.name, draggableProvided);

                    return (
                      <div
                        className="p-block-10"
                        data-section
                        id={item.name}
                        ref={draggableProvided?.innerRef}
                        {...draggableProvided.draggableProps}
                      >
                        {section}
                      </div>
                    );
                  }}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Sections;
