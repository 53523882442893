import Icon from '@ant-design/icons';
import { Button, Col, Row, Space, Steps, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Back } from '../../../Common/Icon';
import ReportRow from '../Reports/Row';
import { REPORT_TABLE_DATA } from '../../../../utils/constantData';
import { customDot } from '../../../../utils';

const Activities = () => {
  const navigate = useNavigate();
  const [reports, setReports] = useState<any[]>();

  useEffect(() => {
    setReports(REPORT_TABLE_DATA);
  }, []);

  const handleBack = () => {
    navigate({ pathname: '/home' });
  };

  const steps =
    reports &&
    reports.map((report) => ({
      status: 'finish' as 'wait' | 'process' | 'finish' | 'error',
      description: (
        <div style={{ paddingTop: '2rem', padding: '10px' }}>
          <ReportRow report={report} isLast />
        </div>
      ),
      className: 'steps',
    }));

  return (
    <section>
      <Row>
        <Col span={24}>
          <Space direction="vertical" size={24}>
            <Space size={4}>
              <Button
                type="ghost"
                icon={<Icon component={Back} style={{ fontSize: '1.25em' }} />}
                onClick={handleBack}
              />
              <Typography.Title>Activities</Typography.Title>
            </Space>
            <Steps
              direction="vertical"
              progressDot={customDot}
              items={[...(steps || []), { status: 'finish', className: 'd-none' }]}
            />
          </Space>
        </Col>
      </Row>
    </section>
  );
};

export default Activities;
