import React, { useCallback, MouseEvent, SetStateAction, Dispatch } from 'react';
import { Radio, RadioChangeEvent, Popover, Button } from 'antd';
import { REPORT_TYPE_OPTIONS } from '../../../constants';
import { COLORS } from '../../../../../../utils/cssUtils';
import { useContextReports } from '../../../../../../context/reports';
import { IReport, useReportUpdateReportType } from '../../../../../../hooks/reports';
import { EnumReportTypes } from '../../../../../../types/reports';

import './style.less';

interface ILoneTypeToggleProps {
  report: IReport | undefined;
  reportType: EnumReportTypes | undefined;
  setReportType: Dispatch<SetStateAction<EnumReportTypes | undefined>>;
}

const LoneTypeToggle = ({ report, setReportType, reportType }: ILoneTypeToggleProps) => {
  const changeReportType = useReportUpdateReportType();
  const { getSettings, getReport } = useContextReports();

  const onTypeChange = ({ target }: RadioChangeEvent) => {
    const value = target.value as EnumReportTypes;

    setReportType(value);
    if (report) {
      changeReportType.fetch({ reportType: value }, report.id).then(() => {
        getReport?.()
        getSettings?.()
      });
    }
  };

  const handleMouseEnter = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.style.borderColor = COLORS.White;
    e.currentTarget.style.color = COLORS.White;
  }, []);

  const handleMouseLeave = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.style.borderColor = COLORS.BorderGrey;
    e.currentTarget.style.color = COLORS.BorderGrey;
  }, []);

  return (
    <Popover
      trigger='click'
      placement='bottom'
      showArrow={false}
      content={
        <Radio.Group
          options={REPORT_TYPE_OPTIONS}
          onChange={onTypeChange}
          value={reportType}
          optionType="button"
          buttonStyle="solid"
          className='custom-radio-group-report' />
      }
    >
      <Button
        ghost
        size='large'
        type='default'
        style={{
          borderColor: COLORS.BorderGrey,
          color: COLORS.BorderGrey
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        Report type
      </Button>
    </Popover>
  )
}

export default LoneTypeToggle;
