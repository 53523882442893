import React, { useState } from 'react';
import { Button, Col, Row, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import MarketRates from './MarketRates';
import Performance from './Performance';
import { USER_DATA } from '../../../utils/constantData';
import { JsonResult } from '../../../types';
import { useReportCreate } from '../../../hooks/reports';
import { fetchIp } from '../../../utils';
import RecentlyViewedReports from './RecentlyViewed/HomeView';
import MostViewedReports from './MostViewedReports';
import Loading from '../../Common/Loading';

const Home = () => {
  const navigate = useNavigate();
  const reportCreate = useReportCreate();
  const profile = USER_DATA;
  const [loading, setLoading] = useState<boolean>(false);

  let hasMarketsPermission = true;

  if (profile && profile.permissions) {
    const permissionsObj = JSON.parse(profile.permissions);

    hasMarketsPermission = profile.type === 'admin' || Object.prototype.hasOwnProperty.call(permissionsObj, 'Markets');
  }

  const handleReportGenerate = () => {
    setLoading(true);
    const loanColor: JsonResult = {};

    fetchIp().then((dataIp) => {
      reportCreate.fetch({ name: '', status: 'draft', loanColor, ip: dataIp.ip }).then((res) => {
        if (!res?.data.id) return;

        navigate(`/reports/${res.data.id}/edit`);
      }).catch(() => {
        navigate(`/reports`);
      }).finally(() => {
        setLoading(false)
      })
    })
  };

  return (
    <div className="flex-col gap-12 content-lg">
      <Loading visible={loading} absolute />
      <div className="table-header">
        <Typography.Title style={{ color: '#464646' }} level={2}>
          Home
        </Typography.Title>
        <Space>
          <Button type="primary" size="large" onClick={handleReportGenerate}>
            Create Report
          </Button>
        </Space>
      </div>
      <Row gutter={[20, 20]}>
        {hasMarketsPermission && (
          <Col xl={16} span={24}>
            <MarketRates />
          </Col>
        )}
        <Col xl={!hasMarketsPermission ? 12 : 8} span={24}>
          <Performance />
        </Col>
        <Col xl={12} span={24}>
          <RecentlyViewedReports />
        </Col>
        <Col xl={12} span={24}>
          <MostViewedReports />
        </Col>
      </Row>
    </div>
  );
};

export default Home;
