import { Space, Form as AntdForm } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import ComplianceLogos from './ComplianceLogos';
import Password from './Password';
import PersonalInfo from './PersonalInfo';
import { USER_DATA } from '../../../../utils/constantData';

const Details = () => {
  const [form] = useForm();
  const editModeState = useState<string | null>(null);
  const profile = USER_DATA;

  useEffect(() => {
    form.resetFields();
  }, [profile]);

  return (
    <section>
      <AntdForm form={form} layout="vertical" autoComplete="off" initialValues={{ ...profile }}>
        <Space direction="vertical" size={20}>
          <PersonalInfo editModeState={editModeState} />
          <Password editModeState={editModeState} />
          <ComplianceLogos />
        </Space>
      </AntdForm>
    </section>
  );
};

export default Details;
