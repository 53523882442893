import { Button, Modal, ModalFuncProps, Space, Typography } from 'antd';

const ShareModalDemo = ({ open, onCancel, afterClose }: ModalFuncProps) => {

  const cancle = () => {
    afterClose?.();
    onCancel?.();
  };

  return (
    <Modal
      style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', gap: '10px' }}
      footer={null} open={open} onCancel={cancle}>
      <Space align='center' style={{ marginBottom: '10px' }} direction="vertical" size={16}>
        <Typography.Title style={{ color: 'black' }} level={2}>Start Free Trial for Live Reports</Typography.Title>
      </Space>
      <Space align='center' style={{ marginBottom: '10px', marginTop: "10px" }} direction="vertical" size={16}>
        <Button
          type="primary"
          size="middle"
          onClick={() => { window.open('https://app.mortgagemaker.ai/sign-up', '_blank') }}
        >
          Click Here
        </Button>
      </Space>
    </Modal>
  );
};

export default ShareModalDemo;
